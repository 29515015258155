.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 0px;
}

.side-bar {
  width: 200px;
  height: 800px;
  margin-top: 90px;
  color: "#f2f3f4" !important;
}

.side-bar div {
  padding-top: 15px;
  font-size: 20px;
  padding-bottom: 15px;
  font-family: "Quicksand", sans-serif;
  cursor: pointer;
}

.current-link div {
  color: #fff;
}

.grid-container-sideBar {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 60px 60px 60px 60px;
}

.side-bar .grid-container-sideBar .basic-link:hover {
  background-color: var(--buttoncolor);
}

.side-bar .grid-container-sideBar a:hover {
  color: white;
}

.side-bar a {
  padding-left: 7px;
  text-decoration: none;
}

.grid-container-sideBar a {
  color: black;
}

.active {
  background-color: gray;
}

.active div {
  color: white;
  text-decoration: none;
}

.button {
  border: none;
}

.basic-link a {
  text-decoration: none;
}

.basic-link .dropdown,
.current-link .dropdown {
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding: 10px;
}

.dropdown.active {
  color: #fff;
}

.current-link .dropdown:hover,
.basic-link .dropdown:hover {
  background-color: var(--buttoncolor);
}

.dropdown .dropdownMenuName {
  margin-left: 15px;
}

@media (max-width: 450px) {
  .side-bar {
    width: 0;
    height: 0;
  }

  .side-bar .dropdown {
    display: none;
  }
}
