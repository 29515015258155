/* footer */

.footer {
  background: var(--footer);
  width: 100%;
}

.footer-menu {
  width: 400px;
  transition: 0.5s all linear;
  text-align: left;
}

.first-footer-menu {
  margin-left: 225px;
  margin-bottom: 125px;
}

.second-footer-menu {
  margin-bottom: 125px;
}

.third-footer-menu {
  margin-bottom: 80px;
}

.footer-menu .footer-item-heading {
  font-family: "Red Hat Display";
  font-style: normal;
  margin-top: 30px;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 33px;
  color: var(--white);
}

.footer-menu .footer-item {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.footer-menu .footer-item .footer-itemLink {
  text-decoration: none;
  color: white;
  font-size: 20px;
}

.flex-container-footer {
  color: hsla(0, 0%, 100%, 0.8);
  font-weight: 100;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.boot-icon {
  font-size: 20px;
  margin-bottom: 5px;
}

@media (max-width: 410px) {
  .footer-menu {
    margin-left: 10px;
  }
  .first-footer-menu {
    margin-left: 10px;
    margin-bottom: 0;
  }

  .second-footer-menu {
    margin-bottom: 0;
  }

  .third-footer-menu {
    margin-bottom: 200px;
  }

  .flex-container {
    flex-direction: column;
  }

}

@media (max-width: 900px) and (device-height: 1180px) {
  .footer-menu {
    width: 800px;
  }

  .first-footer-menu {
    margin-left: 30px;
    margin-bottom: 0;
  }

  .second-footer-menu {
    margin-left: 30px;
    margin-bottom: 0px;
  }

  .third-footer-menu {
    margin-left: 30px;
    margin-bottom: 515px;
  }

  .flex-container-footer {
    flex-direction: row;
    /* was set to column */
  }
}
