@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Nunito:wght@400;700&family=Open+Sans:wght@500&family=Red+Hat+Display:ital,wght@0,300;0,800;1,700&family=Titillium+Web:wght@600&display=swap");

* {
  margin: 0;
  padding: 0;
}

:root {
  --primary: #36454f;
  --secondary: #2f3136;
  --black: black;
  --buttoncolor: #e14024;
  --white: #efefef;
  --pureWhite: #fff;
  --blue: #1a73e8;
  --footer: #5b5b5b;
}
.no-underline {
  text-decoration: none;
}
.pa3 {
  padding: 1rem;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mr2 {
  margin-right: 0.5rem;
}
.pa3 {
  padding: 1rem;
}

.dib {
  display: inline-block;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.card,
.card-small {
  box-shadow: none !important;
}

.ml-2rem {
  margin-left: 2rem;
}

.CodeMirror {
  min-height: 50vh;
  min-width: 45vw;
  font-size: 18px;
  line-height: 1.6;
  padding-top: 20px;
}

.danger-text {
  color: #fe2e2e;
}

.info-text {
  color: #d3d3d3;
}

.showHidePassShow {
  background: transparent;
}
