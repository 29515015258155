#loading {
  background-color: #fafbfe;
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden !important;
  right: 0;
  z-index: 999999;
}
/*#loading-center{background:url(../images/loader.gif) no-repeat scroll 50%;background-size:20%;width:100%;height:100%;position:relative}*/
.loader {
  width: 3em;
  height: 3em;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}
@keyframes Cb {
  0%,
  to {
    box-shadow: 0 -2.6em 0 0 #4788ff, 1.8em -1.8em 0 0 rgba(71, 136, 255, 0.2),
      2.5em 0 0 0 rgba(71, 136, 255, 0.2),
      1.75em 1.75em 0 0 rgba(71, 136, 255, 0.2),
      0 2.5em 0 0 rgba(71, 136, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(71, 136, 255, 0.2),
      -2.6em 0 0 0 rgba(71, 136, 255, 0.5),
      -1.8em -1.8em 0 0 rgba(71, 136, 255, 0.7);
  }
  12.5% {
    box-shadow: 0 -2.6em 0 0 rgba(71, 136, 255, 0.7), 1.8em -1.8em 0 0 #4788ff,
      2.5em 0 0 0 rgba(71, 136, 255, 0.2),
      1.75em 1.75em 0 0 rgba(71, 136, 255, 0.2),
      0 2.5em 0 0 rgba(71, 136, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(71, 136, 255, 0.2),
      -2.6em 0 0 0 rgba(71, 136, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(71, 136, 255, 0.5);
  }
  25% {
    box-shadow: 0 -2.6em 0 0 rgba(71, 136, 255, 0.5),
      1.8em -1.8em 0 0 rgba(71, 136, 255, 0.7), 2.5em 0 0 0 #4788ff,
      1.75em 1.75em 0 0 rgba(71, 136, 255, 0.2),
      0 2.5em 0 0 rgba(71, 136, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(71, 136, 255, 0.2),
      -2.6em 0 0 0 rgba(71, 136, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(71, 136, 255, 0.2);
  }
  37.5% {
    box-shadow: 0 -2.6em 0 0 rgba(71, 136, 255, 0.2),
      1.8em -1.8em 0 0 rgba(71, 136, 255, 0.5),
      2.5em 0 0 0 rgba(71, 136, 255, 0.7), 1.75em 1.75em 0 0 #4788ff,
      0 2.5em 0 0 rgba(71, 136, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(71, 136, 255, 0.2),
      -2.6em 0 0 0 rgba(71, 136, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(71, 136, 255, 0.2);
  }
  50% {
    box-shadow: 0 -2.6em 0 0 rgba(71, 136, 255, 0.2),
      1.8em -1.8em 0 0 rgba(71, 136, 255, 0.2),
      2.5em 0 0 0 rgba(71, 136, 255, 0.5),
      1.75em 1.75em 0 0 rgba(71, 136, 255, 0.7), 0 2.5em 0 0 #4788ff,
      -1.8em 1.8em 0 0 rgba(71, 136, 255, 0.2),
      -2.6em 0 0 0 rgba(71, 136, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(71, 136, 255, 0.2);
  }
  62.5% {
    box-shadow: 0 -2.6em 0 0 rgba(71, 136, 255, 0.2),
      1.8em -1.8em 0 0 rgba(71, 136, 255, 0.2),
      2.5em 0 0 0 rgba(71, 136, 255, 0.2),
      1.75em 1.75em 0 0 rgba(71, 136, 255, 0.5),
      0 2.5em 0 0 rgba(71, 136, 255, 0.7), -1.8em 1.8em 0 0 #4788ff,
      -2.6em 0 0 0 rgba(71, 136, 255, 0.2),
      -1.8em -1.8em 0 0 rgba(71, 136, 255, 0.2);
  }
  75% {
    box-shadow: 0 -2.6em 0 0 rgba(71, 136, 255, 0.2),
      1.8em -1.8em 0 0 rgba(71, 136, 255, 0.2),
      2.5em 0 0 0 rgba(71, 136, 255, 0.2),
      1.75em 1.75em 0 0 rgba(71, 136, 255, 0.2),
      0 2.5em 0 0 rgba(71, 136, 255, 0.5),
      -1.8em 1.8em 0 0 rgba(71, 136, 255, 0.7), -2.6em 0 0 0 #4788ff,
      -1.8em -1.8em 0 0 rgba(71, 136, 255, 0.2);
  }
  87.5% {
    box-shadow: 0 -2.6em 0 0 rgba(71, 136, 255, 0.2),
      1.8em -1.8em 0 0 rgba(71, 136, 255, 0.2),
      2.5em 0 0 0 rgba(71, 136, 255, 0.2),
      1.75em 1.75em 0 0 rgba(71, 136, 255, 0.2),
      0 2.5em 0 0 rgba(71, 136, 255, 0.2),
      -1.8em 1.8em 0 0 rgba(71, 136, 255, 0.5),
      -2.6em 0 0 0 rgba(71, 136, 255, 0.7), -1.8em -1.8em 0 0 #4788ff;
  }
}
@keyframes Db {
  0%,
  to {
    transform: translateX(0);
  }
  75% {
    transform: translateX(30px);
  }
}
@keyframes Eb {
  0%,
  to {
    transform: translateY(0);
  }
  75% {
    transform: translateY(30px);
  }
}
@keyframes Fb {
  0%,
  to {
    transform: translate(0);
  }
  75% {
    transform: translate(30px, 30px);
  }
}
@keyframes Gb {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotate(0deg);
  }
  to {
    transform: rotateX(35deg) rotateY(-45deg) rotate(1turn);
  }
}
@keyframes Hb {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotate(0deg);
  }
  to {
    transform: rotateX(50deg) rotateY(10deg) rotate(1turn);
  }
}
@keyframes Ib {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotate(0deg);
  }
  to {
    transform: rotateX(35deg) rotateY(55deg) rotate(1turn);
  }
}
@keyframes Jb {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(180deg) rotateX(180deg);
  }
}
@keyframes Kb {
  0%,
  to {
    transform: translate(0) rotate(0);
  }
  25% {
    transform: translate(40px, 40px) rotate(45deg);
  }
  50% {
    transform: translateY(80px) rotate(0deg);
  }
  75% {
    transform: translate(-40px, 40px) rotate(45deg);
  }
}
@keyframes Lb {
  0% {
    transform: rotate(0deg);
    border-radius: 50%;
  }
  50% {
    transform: rotate(90deg);
    border-radius: 0;
  }
  to {
    transform: rotate(180deg);
    border-radius: 50%;
  }
}
@keyframes Mb {
  to {
    transform: rotate(1turn);
  }
}
@keyframes Nb {
  0% {
    transform: scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  50% {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
  to {
    transform: scale(1);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
}
@keyframes Ob {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-1turn);
  }
}
@keyframes Pb {
  0% {
    border-width: 0;
  }
  to {
    border-width: 10px;
  }
}
@keyframes Qb {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.iq-loader-box {
  height: 150px;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  -ms-flex-pack: center;
  justify-content: center;
}
.iq-loader-box [class*="loader-"] {
  display: inline-block;
  width: 60px;
  height: 60px;
  color: inherit;
  vertical-align: middle;
  position: relative;
  border-radius: 50%;
}
.iq-loader-box .iq-loader-1 {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: Cb 1.1s infinite ease;
  transform: translateZ(0);
}
.iq-loader-box .iq-loader-2 {
  border: 7px double #37e6b0;
  animation: Qb 1s linear infinite;
}
.iq-loader-box .iq-loader-2:after,
.iq-loader-box .iq-loader-2:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: #37e6b0;
  border-radius: 50%;
  bottom: 0;
  right: 37px;
}
.iq-loader-box .iq-loader-2:after {
  left: 37px;
  top: 0;
}
.iq-loader-box .iq-loader-3 {
  position: relative;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
.iq-loader-box .iq-loader-3 .loader-outter {
  position: absolute;
  border: 4px solid #f50057;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: Qb 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}
.iq-loader-box .iq-loader-3 .loader-inner {
  position: absolute;
  border: 4px solid #f50057;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  animation: Ob 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}
.iq-loader-box .iq-loader-4 {
  border: 0.2em solid transparent;
  border-top-color: #fe721c;
  border-bottom-color: #fe721c;
  border-radius: 50%;
  position: relative;
  animation: 1s Qb linear infinite;
}
.iq-loader-box .iq-loader-4:before {
  transform: rotate(135deg);
  right: -0.3em;
  top: -0.05em;
}
.iq-loader-box .iq-loader-4:after,
.iq-loader-box .iq-loader-4:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border: 0.2em solid transparent;
  border-bottom-color: #fe721c;
}
.iq-loader-box .iq-loader-4:after {
  transform: rotate(-45deg);
  left: -0.3em;
  bottom: -0.05em;
}
.iq-loader-box .iq-loader-5 {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}
.iq-loader-box .iq-loader-5 span {
  display: block;
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  width: 20px;
  height: 20px;
  background-color: var(--info);
}
.iq-loader-box .iq-loader-5 span:first-child {
  animation: Db 1s ease-in-out infinite;
}
.iq-loader-box .iq-loader-5 span:nth-child(2) {
  animation: Eb 1s ease-in-out infinite;
}
.iq-loader-box .iq-loader-5 span:nth-child(3) {
  animation: Fb 1s ease-in-out infinite;
}
.iq-loader-box .iq-loader-6 .inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.iq-loader-box .iq-loader-6 .inner1 {
  left: 0;
  top: 0;
  animation: Gb 1s linear infinite;
  border-bottom: 3px solid #ff4b4b;
}
.iq-loader-box .iq-loader-6 .inner2 {
  right: 0;
  top: 0;
  animation: Hb 1s linear infinite;
  border-right: 3px solid #ff4b4b;
}
.iq-loader-box .iq-loader-6 .inner3 {
  right: 0;
  bottom: 0;
  animation: Ib 1s linear infinite;
  border-top: 3px solid #ff4b4b;
}
.iq-loader-box .iq-loader-7:before {
  content: "";
  position: absolute;
  left: 0;
  width: 50px;
  height: 50px;
  background-color: #3498db;
  animation: Jb 1s infinite;
}
.iq-loader-box .iq-loader-8 {
  border: 1.2em solid transparent;
  border-left-color: #d0bac2;
  border-right-color: #d0bac2;
  border-radius: 50%;
  animation: 1s Qb linear infinite;
}
.iq-loader-box .iq-loader-9 {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 107, 208, 0.2);
  border-right: 1.1em solid rgba(255, 107, 208, 0.2);
  border-bottom: 1.1em solid rgba(255, 107, 208, 0.2);
  border-left: 1.1em solid #ff6bd0;
  transform: translateZ(0);
  animation: Qb 1.1s infinite linear;
}
.iq-loader-box .iq-loader-9,
.iq-loader-box .iq-loader-9:after {
  border-radius: 50%;
  width: 7em;
  height: 7em;
}
.iq-loader-box .iq-loader-10:after,
.iq-loader-box .iq-loader-10:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  left: calc(50% - 10px);
  background-color: #3450db;
  animation: Kb 1s ease-in-out infinite;
}
.iq-loader-box .iq-loader-10:after {
  bottom: 0;
  animation-delay: 0.5s;
}
.iq-loader-box .iq-loader-11 {
  background: #000;
}
.iq-loader-box .iq-loader-11:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin: 0 0 0 -12px;
  background-color: #fff;
  top: auto;
  bottom: 0;
  left: 50%;
  transform-origin: 50% 0;
  animation: Qb 1s infinite linear;
}
.iq-loader-box .iq-loader-12 {
  animation: Lb 1s infinite linear;
  background: #6dca5c;
}
.iq-loader-box .iq-loader-13 {
  border-top: 0.2em solid #a7a4ca;
  border-right: 0.2em solid transparent;
  animation: Mb 1s linear infinite;
  border-radius: 100%;
  position: relative;
}
.iq-loader-box .iq-loader-14 {
  border: 0 solid transparent;
  border-radius: 50%;
  position: relative;
}
.iq-loader-box .iq-loader-14:before {
  animation: Nb 1s linear infinite;
  animation-delay: 1s;
}
.iq-loader-box .iq-loader-14:after,
.iq-loader-box .iq-loader-14:before {
  content: "";
  border: 1em solid #13d7eb;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -ms-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
}
.iq-loader-box .iq-loader-14:after {
  animation: Nb 1s linear infinite;
  animation-delay: 0.5s;
}
.iq-loader-box .iq-loader-15 {
  border: 6px groove #7e57c2;
  transform: rotate(1turn);
  transition: all 1s ease;
  animation: Ob 1s ease-out alternate infinite;
}
.iq-loader-box .iq-loader-15 .loader-inner {
  border: 0 inset #9575cd;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: Pb 1s ease-out alternate infinite;
}
.iq-loader-box .iq-loader-16 {
  width: 70px;
  height: 70px;
  border: 5px dashed #b3a57c;
  animation: Qb 2s linear both infinite;
}

/*new preloader starts*/
@keyframes preload-show-1 {
  from {
    transform: rotateZ(60deg) rotateY(-90deg) rotateX(0);
    border-left-color: #f9c204;
  }
}
@keyframes preload-hide-1 {
  to {
    transform: rotateZ(60deg) rotateY(-90deg) rotateX(0);
    border-left-color: #f9c204;
  }
}
@keyframes preload-cycle-1 {
  5% {
    transform: rotateZ(60deg) rotateY(90deg) rotateX(0);
    border-left-color: #f9c204;
  }
  10%,
  75% {
    transform: rotateZ(60deg) rotateY(0) rotateX(0);
    border-left-color: #f9c204;
  }
  100%,
  80% {
    transform: rotateZ(60deg) rotateY(90deg) rotateX(0);
    border-left-color: #f9c204;
  }
}
@keyframes preload-show-2 {
  from {
    transform: rotateZ(120deg) rotateY(-90deg) rotateX(0);
    border-left-color: #ed8619;
  }
}
@keyframes preload-hide-2 {
  to {
    transform: rotateZ(120deg) rotateY(-90deg) rotateX(0);
    border-left-color: #ed8619;
  }
}
@keyframes preload-cycle-2 {
  10% {
    transform: rotateZ(120deg) rotateY(90deg) rotateX(0);
    border-left-color: #ed8619;
  }
  15%,
  70% {
    transform: rotateZ(120deg) rotateY(0) rotateX(0);
    border-left-color: #ed8619;
  }
  100%,
  75% {
    transform: rotateZ(120deg) rotateY(90deg) rotateX(0);
    border-left-color: #ed8619;
  }
}
@keyframes preload-show-3 {
  from {
    transform: rotateZ(180deg) rotateY(-90deg) rotateX(0);
    border-left-color: #e14024;
  }
}
@keyframes preload-hide-3 {
  to {
    transform: rotateZ(180deg) rotateY(-90deg) rotateX(0);
    border-left-color: #e14024;
  }
}
@keyframes preload-cycle-3 {
  15% {
    transform: rotateZ(180deg) rotateY(90deg) rotateX(0);
    border-left-color: #e14024;
  }
  20%,
  65% {
    transform: rotateZ(180deg) rotateY(0) rotateX(0);
    border-left-color: #e14024;
  }
  100%,
  70% {
    transform: rotateZ(180deg) rotateY(90deg) rotateX(0);
    border-left-color: #e14024;
  }
}
@keyframes preload-show-4 {
  from {
    transform: rotateZ(240deg) rotateY(-90deg) rotateX(0);
    border-left-color: #e14024;
  }
}
@keyframes preload-hide-4 {
  to {
    transform: rotateZ(240deg) rotateY(-90deg) rotateX(0);
    border-left-color: #e14024;
  }
}
@keyframes preload-cycle-4 {
  20% {
    transform: rotateZ(240deg) rotateY(90deg) rotateX(0);
    border-left-color: #f9c204;
  }
  25%,
  60% {
    transform: rotateZ(240deg) rotateY(0) rotateX(0);
    border-left-color: #f9c204;
  }
  100%,
  65% {
    transform: rotateZ(240deg) rotateY(90deg) rotateX(0);
    border-left-color: #f9c204;
  }
}
@keyframes preload-show-5 {
  from {
    transform: rotateZ(300deg) rotateY(-90deg) rotateX(0);
    border-left-color: #a72e28;
  }
}
@keyframes preload-hide-5 {
  to {
    transform: rotateZ(300deg) rotateY(-90deg) rotateX(0);
    border-left-color: #a72e28;
  }
}
@keyframes preload-cycle-5 {
  25% {
    transform: rotateZ(300deg) rotateY(90deg) rotateX(0);
    border-left-color: #a72e28;
  }
  30%,
  55% {
    transform: rotateZ(300deg) rotateY(0) rotateX(0);
    border-left-color: #a72e28;
  }
  100%,
  60% {
    transform: rotateZ(300deg) rotateY(90deg) rotateX(0);
    border-left-color: #a72e28;
  }
}
@keyframes preload-show-6 {
  from {
    transform: rotateZ(360deg) rotateY(-90deg) rotateX(0);
    border-left-color: #ed8619;
  }
}
@keyframes preload-hide-6 {
  to {
    transform: rotateZ(360deg) rotateY(-90deg) rotateX(0);
    border-left-color: #ed8619;
  }
}
@keyframes preload-cycle-6 {
  30% {
    transform: rotateZ(360deg) rotateY(90deg) rotateX(0);
    border-left-color: #ed8619;
  }
  35%,
  50% {
    transform: rotateZ(360deg) rotateY(0) rotateX(0);
    border-left-color: #ed8619;
  }
  100%,
  55% {
    transform: rotateZ(360deg) rotateY(90deg) rotateX(0);
    border-left-color: #ed8619;
  }
}
@keyframes preload-flip {
  0% {
    transform: rotateY(0) rotateZ(-60deg);
  }
  100% {
    transform: rotateY(360deg) rotateZ(-60deg);
  }
}

.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 20px;
  display: block;
  width: 3.75em;
  height: 4.25em;
  margin-left: -1.875em;
  margin-top: -2.125em;
  transform-origin: center center;
  transform: rotateY(180deg) rotateZ(-60deg);
}
.preloader .slice {
  border-top: 1.125em solid transparent;
  border-right: none;
  border-bottom: 1em solid transparent;
  border-left: 1.875em solid #f7484e;
  position: absolute;
  top: 0;
  left: 50%;
  transform-origin: left bottom;
  border-radius: 3px 3px 0 0;
}
.preloader .slice:nth-child(1) {
  transform: rotateZ(60deg) rotateY(0) rotateX(0);
  animation: 0.15s linear 0.82s preload-hide-1 both 1;
}
.preloader .slice:nth-child(2) {
  transform: rotateZ(120deg) rotateY(0) rotateX(0);
  animation: 0.15s linear 0.74s preload-hide-2 both 1;
}
.preloader .slice:nth-child(3) {
  transform: rotateZ(180deg) rotateY(0) rotateX(0);
  animation: 0.15s linear 0.66s preload-hide-3 both 1;
}
.preloader .slice:nth-child(4) {
  transform: rotateZ(240deg) rotateY(0) rotateX(0);
  animation: 0.15s linear 0.58s preload-hide-4 both 1;
}
.preloader .slice:nth-child(5) {
  transform: rotateZ(300deg) rotateY(0) rotateX(0);
  animation: 0.15s linear 0.5s preload-hide-5 both 1;
}
.preloader .slice:nth-child(6) {
  transform: rotateZ(360deg) rotateY(0) rotateX(0);
  animation: 0.15s linear 0.42s preload-hide-6 both 1;
}
.preloader.loading {
  animation: 2s preload-flip steps(2) infinite both;
}
.preloader.loading .slice:nth-child(1) {
  transform: rotateZ(60deg) rotateY(90deg) rotateX(0);
  animation: 2s preload-cycle-1 linear infinite both;
}
.preloader.loading .slice:nth-child(2) {
  transform: rotateZ(120deg) rotateY(90deg) rotateX(0);
  animation: 2s preload-cycle-2 linear infinite both;
}
.preloader.loading .slice:nth-child(3) {
  transform: rotateZ(180deg) rotateY(90deg) rotateX(0);
  animation: 2s preload-cycle-3 linear infinite both;
}
.preloader.loading .slice:nth-child(4) {
  transform: rotateZ(240deg) rotateY(90deg) rotateX(0);
  animation: 2s preload-cycle-4 linear infinite both;
}
.preloader.loading .slice:nth-child(5) {
  transform: rotateZ(300deg) rotateY(90deg) rotateX(0);
  animation: 2s preload-cycle-5 linear infinite both;
}
.preloader.loading .slice:nth-child(6) {
  transform: rotateZ(360deg) rotateY(90deg) rotateX(0);
  animation: 2s preload-cycle-6 linear infinite both;
}
/*new preloader ends*/
