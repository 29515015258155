.verify-email-title {
  font-size: 1rem;
}
.sign-in-box {
  margin-top: 1rem;
}
.verify-password-wrapper .bottom-0 {
  margin-bottom: 0px !important;
}
.verify-password-wrapper .solid-page {
  min-height: unset;
  padding: 3vh;
  padding-left: 30px;
  padding-right: 30px;
}
.verify-password-wrapper .solid-page .loader-wrapper {
  width: 108px;
}
.verify-password-wrapper .solid-page .loader-wrapper .circular-loader {
  height: 12px !important;
  width: 12px !important;
  margin-left: 10px;
}
.verify-password-wrapper .otp-avatar-wrapper {
  padding: 10px;
}
.verify-password-wrapper .otp-avatar-wrapper .MuiAvatar-circular {
  height: 20px;
  width: 20px;
}
.verify-password-wrapper .otp-avatar-wrapper .MuiAvatar-circular .otp-pic {
  height: 50px;
  width: 50px;
}
.verify-password-wrapper .content-box {
  width: 100%;
}
.verify-password-wrapper .content-box.button-box .MuiButton-root {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  font-size: medium !important;
}
.verify-password-wrapper .content-box.button-box .cancel-button {
  width: 100%;
  margin-top: 10px;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.otpContainer {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  gap: 10px;
  padding: 10px;
}
.otpContainer .otpInput {
  font-size: 2rem;
  padding: 10px;
  text-align: center;
  width: 1rem;
  height: 1rem;
  border-radius: var(--primary-border-radius);
  border: var(--primary-border-width) solid var(--primary-border);
  outline: none;
}
.otpContainer .otpInput:focus {
  border-top: 1px solid var(--title-color);
  border-left: 1px solid var(--title-color);
  border-right: 1px solid var(--title-color);
  border-bottom: 5px solid var(--title-color);
}

.link {
  cursor: pointer;
}

.otpButton {
  padding: 8px;
  border: none;
  margin-left: 200px;
  background-color: var(--buttoncolor);
  color: white;
}

.otpboxmain input {
  width: 3em !important;
  height: 3em !important;
  text-align: center;
}

.otpButton span {
  padding: 10px;
}


.otpboxmain{
  margin-top: 42px;
  margin-left: 100px;
}
