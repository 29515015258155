@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');

.buttons {
  margin-top: 10rem;
  transition: 0.5s all linear;
}

.flex-container{
  display: flex;
  flex-wrap: wrap;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 0px;
}

.main-box{
background-color: white;
margin-top: 70px;
}

.main-box p{
  color: black;
  font-size: 17px;
  font-family: 'Quicksand',sans-serif;
}

.main-box h4{
  font-weight: 700;
  font-family: 'Quicksand',sans-serif;
}

.getStartbtn {
  display: inline-block;
  margin-right: 1rem;
  transition: 0.5s all linear;
  font-family: 'Quicksand', sans-serif;
  font-size: 15px;
  margin-left: 30px;
  margin-top: 30px;
}

.getStartbtn a {
  color: var(--buttoncolor);
  border-color: var(--buttoncolor);

  transition: opacity 0.15s ease-in;
  opacity: 1;
  text-decoration: none;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  border-width: 0.125rem;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;

}

.filllcolor {
  background: var(--buttoncolor);
  color: var(--white) !important;
  border-color: var(--buttoncolor);
}

.start-para{
  margin-top: 70px;
  margin-left:30px; 
}

.start-para p{
  margin-top: 20px;
  margin-right: 70px;
}

.mid-para{
  margin-top: 30px;
  margin-left:35px;
  margin-right:70px;
}

.end-para{
 margin-top: 80px;
 margin-left:30px;
 margin-right:70px;
}

.end-para p{
  margin-top: 20px;
}


@media (max-width:450px) {

  .grid-container{
    width: 410px;
  }

}

@media (max-width:900px) and (device-height:1180px) {

  .grid-container{
    width: auto;
  }

}