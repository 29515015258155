.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0px;
  gap: 0px;
}

.apiMainBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0px 15px 15px;
  border-top: thin solid rgb(236, 236, 236);
}

.apileft {
  width: 60%;
}

.apiright {
  width: 40%;
}

.apileft,
.apiright {
  flex: 1;
}

.apileft .urls {
  margin: 20px 0px 10px;
  font-weight: 700;
}

.apileft .urls .title {
  margin-right: 10px;
  font-size: 16px;
  color: rgb(255, 180, 0);
}

.apileft .urls .urldesc {
  display: inline;
  overflow-wrap: break-word;
  color: rgb(40, 40, 40);
  font-size: 16px !important;
}

.urlCard {
  display: inline-block;
  word-break: break-all;
  border: 1px solid rgb(230, 230, 230);
  padding: 6px 10px;
  border-radius: 3px;
  width: 97% !important;
  font-size: 12px;
  color: rgb(40, 40, 40);
  background-color: rgb(248, 248, 248);
  margin: 5px 0px 15px;
  width: 100%;
}

.apileft .dataBody .request_body {
  margin-top: 20px;
}

.apileft .dataBody .request_body .bodytitle {
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid rgb(236, 236, 236);
  margin-bottom: 15px;
  margin-top: 19px;
  font-weight: 600;
}

.apileft .dataBody .request_body .bodytitle .bodybody {
  height: 24px;
  line-height: 24px;
  font-weight: 700;
  font-size: 14px;
  color: rgb(26, 26, 26);
  text-transform: uppercase;
  margin-right: 8px;
}

.apileft .dataBody .request_body .bodytitle .bodyurlcoded {
  color: rgb(102, 102, 102);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.request_body .bodytablestyle {
  display: flex;
  word-break: break-word;
}

.request_body .bodytablestyle .keystyle {
  margin-right: 16px;
  color: rgb(40, 40, 40);
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  padding-right: 10px;
  width: 25%;
}
.request_body .bodytablestyle .keyvalue {
  overflow-wrap: break-word;
  word-break: break-word;
  color: rgb(40, 40, 40);
  line-height: 24px;
  font-weight: 400;
  font-size: 12px;
  width: 75%;
}
