@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Nunito:wght@400;700&family=Open+Sans:wght@500&family=Red+Hat+Display:ital,wght@0,300;0,800;1,700&family=Titillium+Web:wght@600&display=swap");
p {
  color: var(--white);
  font-weight: 300;
}

.top-10 {
  margin-top: 10%;
}

.top-5 {
  margin-top: 5%;
}

.top-2 {
  margin-top: 2%;
}

.logo {
  width: 189px;
  height: 61px;
  margin-bottom: 28px;
  object-fit: cover;
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}


.box-mainauth{
  background-color: #fff;
  padding: 18px;
  margin-top: 4rem;
  border-radius: 5px;
}

a:hover {
  text-decoration: none !important;
}

.content {
  padding: 7rem 0;
}

h2 {
  font-size: 20px;
}

.formContainer input {
  height: 45px;
  border-radius: 3px;
  padding-left: 10px !important;
}

.half .bg {
  background-size: cover;
  background-position: center;
  width: 65%;
}

.title {
  width: 323px;
  height: 40px;
  color: var(--buttoncolor);
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
}

.formContainer .button {
  transition: background-color 0.3s ease-in-out;
  background-color: var(--buttoncolor);
  color: var(--pureWhite);
  border-radius: 5px;
  font-size: 18px;
  height: 52px !important;
  width: 100%;
  font-weight: 500;
  border: none;
}

button:disabled,
button[disabled] {
  cursor: disabled;
}

.formContainer .button:hover {
  color: var(--pureWhite);
}
.link {
  color: var(--buttoncolor) !important;
  width: 187px;
  height: 24px;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.cross {
  border: none;
  color: gray;
  font-size: 30px;
}

.flex-container-login {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: 35px;
}

.passwordbox {
  position: relative;
}

.hideShowMainBox {
  position: absolute;
  right: 1%;
  top: 38%;
}

.hideShowMainBox button {
  border: none;
  height: 4px;
  font-size: 25px;
}

@media (max-width: 450px) {
  .flex-container-login {
    flex-direction: row;
    margin-right: 20px;
  }
}

@media (max-width: 900px) and (device-height: 1180px) {
  .flex-container-login {
    flex-direction: row;
  }
}
